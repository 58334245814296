import getLocalString from "constants/Localization";
import { useUpdateOrganizationConversionFactorOverrideMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateOrganizationConversionFactorOverride() {
  const [mutation, { loading, error, data }] =
    useUpdateOrganizationConversionFactorOverrideMutation();
  const [updateOrganizationConversionFactorOverride, context] = useBitumioMutation(
    {
      mutation,
      data,
      loading,
      error,
      key: "updateOrganizationConversionFactorOverride",
    },
    {
      successMessage: getLocalString("organization.update-conversion-factor-override-success"),
      failureMessage: getLocalString("organization.update-conversion-factor-override-failed"),
      showValidationErrors: true,
    }
  );
  return [updateOrganizationConversionFactorOverride, context] as const;
}
