import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Organization } from "generated/graphql";
import { useForm, useFieldArray } from "react-hook-form";
import MDInput from "components/MDInput";
import { useState } from "react";
import MDButton from "components/MDButton";
import useUpdateOrganizationConversionFactorOverride from "hooks/organization/useUpdateOrganizationConversionFactorOverride";

interface ConversionFactorOverrideProps {
  organization: Organization;
}

interface ConversionOverrideForm {
  asphaltOverrides: { key: string; value: number }[];
  excavateOverrides: { key: string; value: number }[];
}

const parseAndSortOverrides = (jsonString?: string) => {
  if (!jsonString) return [];
  const data = JSON.parse(jsonString);

  return Object.keys(data)
    .sort((a, b) => parseFloat(a) - parseFloat(b))
    .map((key) => ({ key, value: data[key] }));
};

const ConversionFactorOverride = ({ organization }: ConversionFactorOverrideProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [updateConversionFactorOverride, { loading }] =
    useUpdateOrganizationConversionFactorOverride();

  const asphaltData = parseAndSortOverrides(
    organization.conversionFactorOverride?.asphaltTonConversionFactorOverride
  );
  const excavateData = parseAndSortOverrides(
    organization.conversionFactorOverride?.excavateOutTonConversionFactorOverride
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConversionOverrideForm>({
    defaultValues: {
      asphaltOverrides: asphaltData,
      excavateOverrides: excavateData,
    },
    mode: "onChange",
  });

  const { fields: asphaltFields } = useFieldArray({
    control,
    name: "asphaltOverrides",
  });

  const { fields: excavateFields } = useFieldArray({
    control,
    name: "excavateOverrides",
  });

  const onSubmit = async (data: ConversionOverrideForm) => {
    const asphaltOverrides = Object.fromEntries(
      data.asphaltOverrides.map(({ key, value }) => [key, value])
    );

    const excavateOverrides = Object.fromEntries(
      data.excavateOverrides.map(({ key, value }) => [key, value])
    );

    await updateConversionFactorOverride({
      variables: {
        input: {
          id: organization.conversionFactorOverride.id,
          asphaltTonConversionFactorOverride: JSON.stringify(asphaltOverrides),
          excavateOutTonConversionFactorOverride: JSON.stringify(excavateOverrides),
        },
      },
    });
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="center" mb={2}>
          <MDButton color="info" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Hide this section" : "Override Conversion Factors"}
          </MDButton>
        </MDBox>

        <Collapse in={isExpanded}>
          <MDTypography variant="h5" mb={2}>
            Conversion Factor Overrides
          </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ py: 1, pl: 0 }}>
                        <MDTypography variant="h6">Asphalt Ton Conversion</MDTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {asphaltFields.map((field, index) => (
                      <TableRow key={field.id}>
                        <TableCell sx={{ py: 1 }}>{field.key}</TableCell>
                        <TableCell align="right" sx={{ py: 1 }}>
                          <MDInput
                            {...register(`asphaltOverrides.${index}.value` as const, {
                              required: "This field is required",
                              validate: {
                                lessThanOne: (value) => value < 1 || "Value must be less than 1",
                                moreThanZero: (value) =>
                                  value > 0 || "Value must be greater than 0",
                              },
                            })}
                            type="number"
                            inputProps={{
                              step: "0.01",
                              style: { textAlign: "right" },
                            }}
                            sx={{
                              "& .MuiInputBase-input": { py: 0.5 },
                              "& .MuiInputBase-root": { width: "100%" },
                            }}
                            error={!!errors.asphaltOverrides?.[index]?.value}
                            helperText={errors.asphaltOverrides?.[index]?.value?.message}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ py: 1 }}>
                        <MDTypography variant="h6">Excavate Out Ton Conversion</MDTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {excavateFields.map((field, index) => (
                      <TableRow key={field.id}>
                        <TableCell sx={{ py: 1 }}>{field.key}</TableCell>
                        <TableCell align="right" sx={{ py: 1 }}>
                          <MDInput
                            {...register(`excavateOverrides.${index}.value` as const, {
                              required: "This field is required",
                              validate: {
                                lessThanOne: (value) => value < 1 || "Value must be less than 1",
                                moreThanZero: (value) =>
                                  value > 0 || "Value must be greater than 0",
                              },
                            })}
                            type="number"
                            inputProps={{
                              step: "0.01",
                              style: { textAlign: "right" },
                            }}
                            sx={{
                              "& .MuiInputBase-input": { py: 0.5 },
                              "& .MuiInputBase-root": { width: "100%" },
                            }}
                            error={!!errors.excavateOverrides?.[index]?.value}
                            helperText={errors.excavateOverrides?.[index]?.value?.message}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <MDBox mt={3} display="flex" justifyContent="center">
            <MDButton color="success" onClick={handleSubmit(onSubmit)} disabled={loading}>
              Save Changes
            </MDButton>
          </MDBox>
        </Collapse>
      </MDBox>
    </Card>
  );
};

export default ConversionFactorOverride;
