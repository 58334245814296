import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useNavigate } from "react-router";
import useCreateEmployee from "hooks/organization/employees/useCreateEmployee";
import MDInput from "components/MDInput";
import { Controller, useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import { getRoute } from "utils/routing";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import { UserStatus } from "generated/graphql";
import Decimal from "decimal.js";

export default function CreateEmployeePage() {
  const [createEmployee, { loading, error, isSuccess }] = useCreateEmployee();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ firstName, lastName, rate, userId }) => {
    await createEmployee({
      variables: {
        input: {
          firstName,
          lastName,
          rate: parseFloat(new Decimal(rate).times(100).toString()),
          userId,
        },
      },
    });
    if (isSuccess) {
      navigate(getRoute("organization-settings.employees"));
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Create Employee
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox pb={4} pl={4} component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={4}>
              <MDInput
                fullWidth
                margin="normal"
                error={errors.firstName}
                {...register("firstName", { required: true })}
                label="First Name"
              />
              <MDInput
                fullWidth
                margin="normal"
                error={errors.lastName}
                {...register("lastName", { required: true })}
                label="Last Name"
              />
              <MDBox mt={1}>
                <OrganizationUsersOptions
                  queryOverride={{ first: 1000, status: UserStatus.ACTIVE }}
                >
                  {({ organizationUsersOptions, loading }) => (
                    <Controller
                      control={control}
                      name="userId"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <AutoComplete
                            options={organizationUsersOptions}
                            loading={loading}
                            variant="outlined"
                            field={{
                              onChange: field.onChange,
                              onBlur: field.onBlur,
                              value: field.value,
                              name: field.name,
                              ref: field.ref,
                            }}
                            getOptionDisabled={(option) => option.hasEmployee}
                            error={error}
                            label="Select User"
                          />
                        </>
                      )}
                    />
                  )}
                </OrganizationUsersOptions>
              </MDBox>
              <MDInput
                type="number"
                inputProps={{ step: "0.01" }}
                fullWidth
                margin="normal"
                error={errors.rate}
                {...register("rate", { required: true, min: 1, valueAsNumber: true })}
                label="Rate"
              />
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
