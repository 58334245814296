import MDButton from "components/MDButton";
import { Proposal, ProposalStatus } from "generated/graphql";
import useCloseProposal from "hooks/proposals/useCloseProposal";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MoveToClosedLostIcon from "../../../../assets/images/movetoclosedlost-proposal.svg";

export default function MoveProposalToClosed({
  id,
  status,
}: {
  id: Proposal["id"];
  status: Proposal["status"];
}) {
  const [closeProposal] = useCloseProposal({ id });
  const validStatuses = ["SENT", "DRAFT"];
  const isDisabled = !validStatuses.includes(status);

  return (
    <Tooltip title="Move to Closed/Lost">
      <IconButton
        disabled={isDisabled}
        onClick={async () => {
          await closeProposal({
            variables: { id },
          });
        }}
      >
        <img
          src={MoveToClosedLostIcon}
          alt="Move To Closed/Lost"
          style={{
            filter: isDisabled ? "invert(70%) hue-rotate(120deg)" : "invert(0%) hue-rotate(120deg)",
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
