import { zodResolver } from "@hookform/resolvers/zod";
import {
  Card,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  Switch,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { OrganizationSalesTax, UpdateOrganizationSalesTaxesInput } from "generated/graphql";
import useGetQuickbooksTaxCodesLazy from "hooks/organization/salesTax/useGetQuickbooksTaxCodesLazy";
import useUpdateOrganizationSalesTax from "hooks/organization/salesTax/useUpdateOrganizationSalesTax";
import BitumioContext from "providers/BitumioContext";
import { omit } from "ramda";
import React, { useContext, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import * as z from "zod";

const defaultValues = {
  name: "",
  percentage: 0,
  isDefault: false,
};

const schema = z.object({
  id: z.string(),
  salesTaxes: z.object({
    upsert: z.array(
      z.object({
        id: z.string().optional(),
        name: z.string().min(1, "Name is Required"),
        percentage: z.number().min(0, "Percentage is Required"),
        isDefault: z.boolean(),
        quickbooksTaxCodeId: z.string().optional().or(z.null()),
        quickbooksDesktopTaxCodeId: z.string().optional().or(z.null()),
      })
    ),
  }),
});

export default function OrganizationSalesTaxForm({
  salesTaxes,
  hasLinkedQuickbooks,
}: {
  salesTaxes: OrganizationSalesTax[];
  hasLinkedQuickbooks: boolean;
}) {
  const { signedInUser } = useContext(BitumioContext);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<UpdateOrganizationSalesTaxesInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      id: signedInUser.organization.id,
      salesTaxes: {
        upsert: salesTaxes.map((tax) => {
          return omit(["__typename"])(tax);
        }),
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "salesTaxes.upsert",
  });

  const [updateOrganizationSalesTax] = useUpdateOrganizationSalesTax();
  const [getQuickbooksTaxCodes, { taxCodes }] = useGetQuickbooksTaxCodesLazy();

  useEffect(() => {
    if (hasLinkedQuickbooks) {
      getQuickbooksTaxCodes();
    }
  }, [hasLinkedQuickbooks]);

  const qbTaxCodeOptions = useMemo(() => {
    return taxCodes.map((val) => {
      return {
        value: val.id,
        label: val.name,
      };
    });
  }, [taxCodes]);

  return (
    <Card
      role="form"
      component="form"
      onSubmit={handleSubmit((input) => {
        updateOrganizationSalesTax({
          variables: { input },
        });
      })}
    >
      <MDBox p={3} className="organization-tax-rates">
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Grid container spacing={3} key={index}>
              <Grid item xs={12} md={3}>
                <FormField
                  label="Name"
                  defaultValue={field.name}
                  error={errors.salesTaxes?.upsert?.[index]?.name}
                  {...register(`salesTaxes.upsert.${index}.name`)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormField
                  label="Percentage"
                  defaultValue={field.name}
                  error={errors.salesTaxes?.upsert?.[index]?.percentage}
                  {...register(`salesTaxes.upsert.${index}.percentage`, { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <Controller
                  name={`salesTaxes.upsert.${index}.isDefault`}
                  control={control}
                  render={({ field: inputField }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch />}
                        label="Default"
                        checked={inputField.value}
                        onChange={(e, checked) => {
                          inputField.onChange(checked);
                        }}
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
              {hasLinkedQuickbooks && taxCodes && (
                <>
                  <Grid item xs={12} md={2}>
                    <CustomSelect
                      control={control}
                      name={`salesTaxes.upsert.${index}.quickbooksTaxCodeId`}
                      data={qbTaxCodeOptions}
                      placeholder="Quickbooks Tax Code"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} md={1} sx={{ paddingLeft: "5px !important" }}>
                    <IconButton
                      onClick={() =>
                        setValue(`salesTaxes.upsert.${index}.quickbooksTaxCodeId`, null)
                      }
                    >
                      <Icon fontSize="small">cancel</Icon>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name={`salesTaxes.upsert.${index}.quickbooksDesktopTaxCodeId`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          placeholder="Quickbooks Desktop Tax Code"
                          {...field}
                          type="number"
                          label=""
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={1} textAlign="right">
                <IconButton
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <Icon color="error">delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
        {errors?.salesTaxes?.upsert && (
          <MDTypography color="error" variant="caption">
            {errors.salesTaxes.upsert.message}
          </MDTypography>
        )}

        <MDBox mt={2} display="flex" justifyContent="space-between">
          <MDButton
            type="button"
            variant="gradient"
            color="secondary"
            onClick={() => {
              append(defaultValues);
            }}
          >
            Add Sales Tax
          </MDButton>
          <MDButton type="submit" variant="gradient" color="success">
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}
